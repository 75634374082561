<script setup lang="ts">
import { useImage } from '#image/composables'
import { computed } from 'vue'

defineProps<{
  commentaires: {
    name: string
    text: string
  }[]
}>()

const $img = useImage()
const cssBgFlowerUrl = computed(() => `url(${$img('/img/eliah_picto-violetclair.png', {
  quality: 80,
  format: 'webp',
  height: 600,
})})`)
</script>

<template>
  <div class="bg-flower min-h-100 w-full text-void">
    <div class="flex flex-col items-center justify-center bg-lavender-200 bg-opacity-80 px-8 py-20">
      <h3 class="mb-20 text-center text-2xl font-bold font-laviossa">
        Témoignages
      </h3>
      <div class="grid grid-cols-1 gap-8 font-gravity lg:grid-cols-4 md:grid-cols-2 compact-container">
        <div v-for="(comment, i) in commentaires" :key="i" class="flex flex-col">
          <div class="flex-grow">
            <h4 class="font-bold">
              {{ comment.name }}
            </h4>

            <p class="text-justify text-void font-light leading-7">
              {{ comment.text }}
            </p>
          </div>

          <div class="mt-3">
            <NuxtLink class="text-sunset font-gravity uppercase" to="/temoignages">
              Voir plus
            </NuxtLink>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.bg-flower {
  background-image: v-bind('cssBgFlowerUrl'), v-bind('cssBgFlowerUrl'), v-bind('cssBgFlowerUrl');
  background-repeat: no-repeat;
  background-size: 400px;
  background-position: left -130px top -200px, center top -200px, right -130px top -200px;
}

@media (max-width: 1023px) { /* <lg */
  .bg-flower {
    background-image: v-bind('cssBgFlowerUrl'), none, v-bind('cssBgFlowerUrl');
  }
}

@media (max-width: 639px) { /* <sm */
  .bg-flower {
    background-image: none, v-bind('cssBgFlowerUrl'), none;
  }
}
</style>
